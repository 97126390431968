// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-precios-index-jsx": () => import("./../../../src/pages/precios/index.jsx" /* webpackChunkName: "component---src-pages-precios-index-jsx" */),
  "component---src-pages-soluciones-sistema-pos-index-jsx": () => import("./../../../src/pages/soluciones/sistema-pos/index.jsx" /* webpackChunkName: "component---src-pages-soluciones-sistema-pos-index-jsx" */),
  "component---src-pages-tipos-negocios-farmacias-droguerias-index-jsx": () => import("./../../../src/pages/tipos-negocios/farmacias-droguerias/index.jsx" /* webpackChunkName: "component---src-pages-tipos-negocios-farmacias-droguerias-index-jsx" */),
  "component---src-pages-tipos-negocios-mantenimiento-reparacion-equipos-index-jsx": () => import("./../../../src/pages/tipos-negocios/mantenimiento-reparacion-equipos/index.jsx" /* webpackChunkName: "component---src-pages-tipos-negocios-mantenimiento-reparacion-equipos-index-jsx" */),
  "component---src-pages-tipos-negocios-supermercados-index-jsx": () => import("./../../../src/pages/tipos-negocios/supermercados/index.jsx" /* webpackChunkName: "component---src-pages-tipos-negocios-supermercados-index-jsx" */),
  "component---src-pages-tipos-negocios-venta-al-por-menor-de-zapatos-y-moda-index-jsx": () => import("./../../../src/pages/tipos-negocios/venta-al-por-menor-de-zapatos-y-moda/index.jsx" /* webpackChunkName: "component---src-pages-tipos-negocios-venta-al-por-menor-de-zapatos-y-moda-index-jsx" */)
}

